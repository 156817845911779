import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { chunk, uniqBy } from 'lodash/array';
import SnippetCard from '@components/organisms/SnippetCard/SnippetCard';
import MarsIcon from '@components/atoms/MarsIcon/MarsIcon';
import FlyIn from '@components/atoms/FlyIn/FlyIn';
import SearchSnippetCardListFilters from '@components/molecules/SearchSnippetCardListFilters/SearchSnippetCardListFilters';
import filtersArray from './filters';
import categoryFilters from './filters/category';

import './SearchSnippetCardList.scss';

const PAGINATION_CUTOFF = 20;
const RESULTS_PER_PAGE = 15;

const SearchSnippetCardList = ({
  preFilter = false,
  results = [],
  onSearch
}) => {
  let snippetItems = null;
  const [currentPage, setCurrentPage] = useState(0);
  const [activeFilters, setActiveFilters] = useState(0);
  const [filteredResults, setFilteredResults] = useState(results);
  const [resultsToDisplay, setResultsToDisplay] = useState(results);
  const [categoryFilter, setCategoryFilter] = useState(-1);
  const [filtersFlyIn, setFiltersFlyIn] = useState(false);

  useEffect(() => {
    if (preFilter) {
      return onFilterChange([{ filter: `category`, value: `key_fact` }]);
    }
    onFilterChange([]);
  }, [results]);

  useEffect(() => {
    categoryChange(categoryFilter);
  }, [categoryFilter, filteredResults]);

  // if there are less than 30 results, dont bother to paginate
  if (resultsToDisplay.length < PAGINATION_CUTOFF) {
    snippetItems = resultsToDisplay.map(mapResults);
  } else {
    // paginate RESULTS_PER_PAGE results at a time
    const pages = chunk(resultsToDisplay, RESULTS_PER_PAGE);
    snippetItems = pages[currentPage].map(mapResults);
  }

  const categoryChange = (categoryFilter) => {
    setCurrentPage(0);
    if (categoryFilter === -1) {
      setResultsToDisplay(filteredResults);
      return;
    }
    const results = applyCategoryFilter(filteredResults, categoryFilter);
    setResultsToDisplay(results);
  };

  const applyCategoryFilter = (results, category) => {
    return results.filter((results) => results.category === category);
  };

  const onFilterChange = (filters) => {
    setCurrentPage(0); // Reset pagination to beginning
    setActiveFilters(filters.length);
    if (filters.length === 0) {
      return setFilteredResults(results);
    }

    // Group the flat filters array into their types
    const filterTypes = filters.reduce((filterTypes, filterOption) => {
      if (!Array.isArray(filterTypes[filterOption.filter])) {
        filterTypes[filterOption.filter] = [];
      }
      filterTypes[filterOption.filter].push(filterOption);

      return filterTypes;
    }, {});

    /**
     * Filters an array of snippets based on filter type
     * @param {array} results A list of snippets to filter
     * @param {string} filterType The filter type such as category, brand, market etc.
     * @returns {array} A filtered list of snippets
     */
    const filterResults = (results, filterType) => {
      const filters = filterTypes[filterType];
      const filtered = [];
      filters.forEach((option) => {
        results.forEach((result) => {
          let val = result[option.filter];
          if (option.parent && result[option.parent]) {
            val = result[option.parent][option.filter];
          }

          if (Array.isArray(val) && val.includes(option.value)) {
            filtered.push(result);
          } else if (val === option.value) {
            filtered.push(result);
          }
        });
      });

      return filtered;
    };

    // Filter the results again and again for each of the filter types (Category, Brand, Market etc.).
    const filtered = Object.keys(filterTypes).reduce(filterResults, results);

    setFilteredResults(uniqBy(filtered, `id`));
  };

  const toggleCategory = (e, category) => {
    e.preventDefault();
    if (category === categoryFilter) {
      setCategoryFilter(-1);
      return;
    }
    setCategoryFilter(category);
  };

  const getIconName = (category) =>
    `content` +
    category[0].toUpperCase() +
    category.slice(1).toLowerCase().replace(`_`, ``);

  return (
    <div>
      <FlyIn
        isOpen={filtersFlyIn}
        left
        onClose={() => {
          setFiltersFlyIn(false);
        }}>
        <SearchSnippetCardListFilters
          preFilter={preFilter}
          onFilterChange={onFilterChange}
          filters={filtersArray(results)}
        />
      </FlyIn>
      <div className="mars-search-filters__filter-bar">
        <button
          className="mars-button mars-margin-bottom--lg"
          onClick={() => {
            setFiltersFlyIn(!filtersFlyIn);
          }}>
          <MarsIcon icon="filter" width="16px" height="16px" />
          {`Filter ${activeFilters > 0 ? `(${activeFilters})` : ``}`}
        </button>
      </div>
      <div className="mars-search-snippet-card-list__snippets">
        <div className="mars-search-snippet-card-list__categories mars-padding-right--md">
          <h4 className="mars-body-small">Categories</h4>
          <ul className="no-scroll">
            {categoryFilters(filteredResults).options.map((category, index) => (
              <li
                key={category.name + index}
                onClick={(e) => toggleCategory(e, category.name)}
                className={classnames(
                  `mars-search-snippet-card-list__category`,
                  //`mars-padding-y--xxs`,
                  {
                    'mars-search-snippet-card-list__category--active':
                      categoryFilter === category.name
                  }
                )}>
                <MarsIcon
                  icon={getIconName(category.name)}
                  width="16px"
                  height="16px"
                  className={`mars-margin-x--xxs mars-icon--${category.name.replace(
                    `_`,
                    ``
                  )}`}
                />
                <span className="mars-zeta">
                  {
                  // If cat name is gain point, change the label to delight.
                  // ToDo: Refactor conditional statement code when the snippet types have been renamed in the CMS.
                  category.name === `gain_point` ? `Delight` : category.name.replace(`_`, ` `)}
                </span>
                <span className="mars-body-tiny mars-text-colour--text-meta mars-padding-x--xxs">
                  ({category.count})
                </span>
              </li>
            ))}
            <li
              onClick={(e) => toggleCategory(e, -1)}
              className="mars-search-snippet-card-list__category mars-zeta mars-margin-left--xxs mars-margin-y--xs">
              ALL
            </li>
          </ul>
        </div>

        <ul
          className={`mars-search-snippet-card-list mars-cols mars-padding-bottom--sm ${
            onSearch ? `mars-cols--2-cols` : `mars-cols--4-cols`
          }`}>
          {resultsToDisplay.length > 0 && snippetItems}
        </ul>
      </div>
      {resultsToDisplay.length > 0 && (
        <ReactPaginate
          previousLabel={`previous`}
          nextLabel={`next`}
          breakLabel={`...`}
          breakClassName={`break-me`}
          pageCount={Math.ceil(resultsToDisplay.length / RESULTS_PER_PAGE)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={({ selected }) => {
            setCurrentPage(selected);
            window.scrollTo({
              top: 0,
              behavior: `smooth`
            });
          }}
          containerClassName={`mars-pagination`}
          subContainerClassName={`pages pagination`}
          activeClassName={`active`}
        />
      )}
    </div>
  );
};

const mapResults = (
  {
    category = ``,
    description,
    id,
    image,
    moment,
    momentUrl,
    market,
    source,
    title
  },
  index
) => {
  return (
    <li
      className="mars-search-snippet-card-list__item mars-margin-bottom--md"
      key={id || index}>
      <SnippetCard
        location={location}
        id={id}
        taxonomy={category.replace(`_`, ``)}
        rawText={description}
        image={image}
        imageTitle={title}
        market={market}
        source={source}
        moment={moment}
        momentUrl={momentUrl}
        onSearch={true}
      />
    </li>
  );
};

export default SearchSnippetCardList;

SearchSnippetCardList.propTypes = {
  results: PropTypes.array.isRequired
};
