import React, { useState } from 'react';
import { useElastic } from '@state/providers/elastic';
import TabbedContent from '@components/organisms/TabbedContent/TabbedContent';
import SearchSnippetCardList from '@components/organisms/SearchSnippetCardList/SearchSnippetCardList';
import SearchInput from '@components/molecules/Search/Search';
import NoSavedItems from '@components/atoms/NoSavedItems/NoSavedItems';
import OpenMsFormButton from '@components/molecules/OpenMsFormButton/OpenMsFormButton';

import Tab from './Tab';

const SearchTemplate = ({ location }) => {
  const [searched, setSearched] = useState(false);
  const [term, setTerms] = useState(``);

  const {
    searching,
    results: { moments, dataSnippets, articles }
  } = useElastic(`_all`, `term`, term.toLowerCase());

  const initialTab =
    location.state?.referrer === `moment` && term === location.state?.term
      ? 1
      : 0;

  return (
    <div className="mars-container mars-margin-y--xl">
      <SearchInput
        onSearch={(term) => {
          setSearched(true);
          setTerms(term);
        }}
      />
      {searching && (
        <section className="mars-margin-top--lg">
          <NoSavedItems
            icon="research"
            title="Please wait"
            description="Searching..."
          />
        </section>
      )}
      {!searching && (
        <section className="mars-margin-top--lg">
          {!searched && (
            <NoSavedItems
              icon="research"
              title="Your search results will appear here."
              description="Type in the search box and hit enter."
            />
          )}

          {searched && (
            <TabbedContent
              tabListClassName="mars-margin-bottom--lg"
              variant="secondary"
              initialTab={initialTab}>
              <div data-label={`MOMENTS (${moments.length})`}>
                {moments.length > 0 && <Tab results={moments} type="moment" />}
                {moments.length === 0 && (
                  <NoSavedItems
                    icon="research"
                    title="Your search didn't find any moments."
                  />
                )}
              </div>
              <div data-label={`DATA POINTS (${dataSnippets.length})`}>
                {dataSnippets.length > 0 && (
                  <SearchSnippetCardList
                    results={dataSnippets}
                    onSearch={true}
                  />
                )}
                {dataSnippets.length === 0 && (
                  <NoSavedItems
                    icon="research"
                    title="Your search didn't find any data points."
                  />
                )}
              </div>
              <div data-label={`ARTICLES (${articles.length})`}>
                {articles.length > 0 && (
                  <Tab results={articles} type="article" />
                )}
                {articles.length === 0 && (
                  <NoSavedItems
                    icon="research"
                    title="Your search didn't find any articles."
                  />
                )}
              </div>
            </TabbedContent>
          )}
        </section>
      )}
      <OpenMsFormButton formUrl="https://forms.office.com/Pages/ResponsePage.aspx?id=OskoFRcZoE-1ALqcjkyZPyrIsmwGEu9EpNkZBhIzltBUNTZPWEVGOVJQSVg3QUdYTEdGQkNJM0laUy4u&embed=true" />
    </div>
  );
};

export default SearchTemplate;
