import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import { chunk } from 'lodash/array';
import SearchResultsCard from '@components/molecules/SearchResultsCard/SearchResultsCard';
import ArticleCard from '@components/molecules/ArticleCard/ArticleCard';

const PAGINATION_CUTOFF = 10;
const RESULTS_PER_PAGE = 8;

const Tab = ({ results, type }) => {
  let searchResults = null;
  const [currentPage, setCurrentPage] = useState(0);

  // if there are less than 30 results, dont bother to paginate
  if (results.length < PAGINATION_CUTOFF) {
    searchResults = results;
  } else {
    // paginate RESULTS_PER_PAGE results at a time
    const pages = chunk(results, RESULTS_PER_PAGE);
    searchResults = pages[currentPage];
  }

  return (
    <div
      className={`mars-margin-y--xl ${
        type ? `mars-container mars-container--820` : ``
      }`}>
      {searchResults &&
        searchResults.map((r, index) => {
          if (type === `article`) {
            const articleData = {
              title: r.title,
              url: r.url,
              imageUrl: r.imageUrl,
              imageAlt: r.imageAlt,
              created: Object.freeze(new Date(r.created * 1000)),
              readTime: r.readTime
            };
            return <ArticleCard data={articleData} key={r.id + index} />;
          }
          return (
            <SearchResultsCard result={r} type={type} key={r.id + index} />
          );
        })}

      {results.length > 0 && (
        <ReactPaginate
          previousLabel={`previous`}
          nextLabel={`next`}
          breakLabel={`...`}
          breakClassName={`break-me`}
          pageCount={Math.ceil(results.length / RESULTS_PER_PAGE)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={({ selected }) => {
            setCurrentPage(selected);
          }}
          containerClassName={`mars-pagination`}
          subContainerClassName={`pages pagination`}
          activeClassName={`active`}
        />
      )}
    </div>
  );
};

export default Tab;
