import React, { useEffect } from 'react';
import MarsIcon from '@components/atoms/MarsIcon/MarsIcon';

import './Search.scss';
import { useLocalStorage } from '@helpers';

const Search = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useLocalStorage(`searchTerm`, ``);

  useEffect(() => {
    onSubmit();
  }, []);

  const onSubmit = (e) => {
    e && e.preventDefault();
    if (searchTerm && searchTerm !== ``) {
      onSearch(searchTerm);
    }
  };

  return (
    <div className="mars-search">
      <form className="mars-search__form" onSubmit={onSubmit}>
        <input
          type="text"
          name="search-term"
          className="mars-text-input mars-search__form-input"
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button className="mars-button mars-search__button">Search</button>
        <MarsIcon
          icon="search"
          className="mars-search__form-input-icon"
          width="1em"
          height="1em"
        />
      </form>
    </div>
  );
};

export default Search;
