import React from 'react';

import Layout from '@components/Layout';
import SEO from '@components/SEO';
import SearchTemplate from '@components/templates/SearchTemplate/SearchTemplate';
import GTMPageLoad from '@components/atoms/GTM/GTMPageLoad';

import '@styles/site.scss';

const SearchPage = ({ location }) => {
  const { pathname } = location;
  return (
    <Layout location={location} title={``}>
      <GTMPageLoad
        {...{
          page_type: `search`,
          page_taxonomy_field: `Search`,
          page_name: `Search`
        }}
      />
      <SEO title="Home" />
      <SearchTemplate
        searchTerm={pathname.replace(`/search/`, ``)}
        location={location}
      />
    </Layout>
  );
};

export default SearchPage;
