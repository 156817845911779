import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import Link from 'gatsby-link';
import classnames from 'classnames';
import actions from '@state/actions';
import { useInCollections, getTextClassFromLength } from '@helpers';
import Card from '@components/atoms/Card/Card';
import MarsIcon from '@components/atoms/MarsIcon/MarsIcon';
import Modal from '@components/molecules/Modal/Modal';

import './SnippetCard.scss';
import { getDrupalURL } from '@helpers';

const SnippetCard = ({
  location,
  id,
  taxonomy,
  rawText,
  image,
  imageTitle,
  market,
  source,
  moment,
  momentUrl,
  onSearch
}) => {
  const dispatch = useDispatch();
  const collection = useSelector((state) => state.collections.collection);
  const isInCollections = useInCollections(`dataSnippets`, id);
  const focusOnModalCloseRef = useRef(null);
  const text = rawText ? rawText.replace(/(<([^>]+)>)/gi, ``) : null;

  // State for Snippet Source information modal.
  const [isOpenSource, setIsOpenSource] = useState(false);

  const handleCardClick = (e) => {
    if (location.pathname === `/collection` && momentUrl) {
      return navigate(momentUrl);
    }

    if (source) openSource(e);
  };

  const openSource = (e) => {
    e.stopPropagation();
    setIsOpenSource(true);
  };

  const closeSource = () => {
    setIsOpenSource(false);
    focusOnModalCloseRef.current.focus();
  };

  const onActionBtnClick = (e, taxonomy) => {
    e.stopPropagation();
    if (typeof collection !== `undefined`) {
      dispatch(
        actions.openRemoveFromCollection(
          // Content snippets are properties on a Moment so will have no Taxonomy whereas
          // Data snippets are related items.
          typeof taxonomy === `string` && taxonomy !== ``
            ? `dataSnippets`
            : `contentSnippets`,
          id,
          focusOnModalCloseRef
        )
      );
    } else {
      openAddToCollection();
    }
  };

  const openAddToCollection = () => {
    dispatch(
      actions.openAddToCollection(
        `dataSnippets`,
        { id, text },
        focusOnModalCloseRef
      )
    );
  };

  const onAddToCollectionFromWithinSourceModal = () => {
    // Programatically close Source modal.
    closeSource();
    // Open Add to Collection modal.
    openAddToCollection();
  };

  console.log(image);

  const getShortenedText = () => {
    return text.length > 330 ? `${text.substring(0, 330)}...` : text;
  };

  // Convert taxonomy into MarsIcon icon string prop.
  // If it fails then add 'placeholder' icon for now to enable us to track down bad/missing data easier.
  const taxonomyIcon =
    typeof taxonomy === `string` && taxonomy !== ``
      ? `content${(
          taxonomy.charAt(0).toUpperCase() + taxonomy.slice(1)
        ).replace(/\s/g, ``)}`
      : `activities`;

  // Get the color from base -> primary colors if defined
  const iconColourClass =
    taxonomyIcon === `activities`
      ? ``
      : `mars-icon--${taxonomy.replace(/\s/g, ``)}`;

  return (
    <>
      <Card className="mars-card--hover-reveal">
        <div
          className="mars-snippet-card"
          ref={focusOnModalCloseRef}
          tabIndex="0"
          onClick={handleCardClick}>
          <button
            className="mars-snippet-card__save-btn mars-card__save-btn mars-button--svg"
            onClick={(e) => onActionBtnClick(e, taxonomy)}
            aria-label="Save">
            <MarsIcon icon="trash" className="mars-card__trash-icon" />
            <MarsIcon
              icon="save"
              className={classnames(`mars-card__save-icon`, {
                'mars-icon--fill': isInCollections
              })}
            />
          </button>
          <div className="mars-snippet-card__taxonomy mars-zeta mars-text-colour--text-brand1">
            {taxonomyIcon && (
              <MarsIcon
                icon={taxonomyIcon}
                width="16px"
                height="16px"
                className={`mars-snippet-card__taxonomy-icon ${iconColourClass}`}
              />
            )}
            {!onSearch && <span>{taxonomy || `Knowledge`}</span>}
          </div>
          {image && !text && (
            <figure className="mars-snippet-card__image">
              <img
                className="mars-margin-bottom--xxs"
                src={`${image}`}
                alt={imageTitle}
              />
            </figure>
          )}
          {text && (
            // The value is used with dangerouslySetInnerHTML to preserve the HTML markup.
            <p
              className="mars-snippet-card__text mars-body-large mars-text-colour--brand1"
              dangerouslySetInnerHTML={{ __html: getShortenedText(text) }}
            />
          )}
          {source && (
            <div className="mars-snippet-card__footer">
              <button
                className="mars-button mars-button--secondary"
                onClick={openSource}>
                <span className="mars-button__text">Learn more</span>
              </button>
            </div>
          )}
        </div>
      </Card>

      <Modal
        isOpen={isOpenSource}
        onDismiss={closeSource}
        className="mars-modal--12-col">
        <div className="mars-snippet-source mars-grid">
          <div className="mars-snippet-source__left-col mars-grid-col-4">
            <h3 className="mars-zeta mars-text-colour--text-meta">Moment</h3>
            <p className="mars-margin-bottom--lg">
              <Link to={momentUrl} className="mars-link">
                {moment}
              </Link>
            </p>
            {source && source.title && (
              <div className="mars-margin-bottom--md">
                <h3 className="mars-zeta mars-text-colour--text-meta">
                  Document Info
                </h3>
                <p className="mars-epsilon">{source.title}</p>
                <div className="mars-text-colour--text-meta mars-body-small">
                  {source && source.pageNumber && (
                    <span>
                      Page:{` `}
                      {source.pageNumber}
                    </span>
                  )}
                  {source && source.pageNumber && source.publishedDate && (
                    <span className="mars-margin-x--xxs">|</span>
                  )}
                  {source && source.publishedDate && (
                    <span>
                      Published:{` `}
                      {new Date(source.publishedDate).toLocaleDateString()}
                    </span>
                  )}
                </div>
              </div>
            )}
            {source && source.commissionedBy && (
              <>
                <h3 className="mars-zeta mars-text-colour--text-meta">
                  Commissioned by
                </h3>
                <p className="mars-epsilon mars-margin-bottom--md">
                  {source.commissionedBy}
                </p>
              </>
            )}
            {source && source.link && (
              <>
                <h3 className="mars-zeta mars-text-colour--text-meta">
                  Source
                </h3>
                <p className="mars-epsilon mars-margin-bottom--md">
                  <a className="mars-link" target="_blank" href={source.link}>
                    {source.linkText}
                  </a>
                </p>
              </>
            )}
            {market && (
              <>
                <h3 className="mars-zeta mars-text-colour--text-meta">
                  Market
                </h3>
                <p className="mars-epsilon mars-margin-bottom--md">{market}</p>
              </>
            )}
          </div>

          <div className="mars-snippet-source__right-col mars-grid-col-8">
            <h3 className="mars-snippet-source__title mars-zeta mars-margin-bottom--xs">
              <MarsIcon
                icon={taxonomyIcon}
                width="1.5rem"
                height="1.5rem"
                className={`mars-snippet-source__title-icon ${iconColourClass}`}
              />
              {
                // If tax name is gain point, change the label to delight.
                // ToDo: Refactor conditional statement code when the snippet types have been renamed in the CMS.
                taxonomy === `gainpoint` ? `Delight` : taxonomy
              }
            </h3>
            {image && (
              <figure className="mars-snippet-source__image mars-margin-bottom--lg">
                <img
                  className="mars-margin-bottom--xxs"
                  src={`${image}`}
                  alt={imageTitle}
                />
              </figure>
            )}
            {text && (
              // The value is used with dangerouslySetInnerHTML to preserve the HTML markup.
              <div
                className={`${getTextClassFromLength(
                  text
                )} mars-text-color--brand1 mars-margin-bottom--md`}
                dangerouslySetInnerHTML={{ __html: text }}
              />
            )}
            <button
              className="mars-button"
              onClick={onAddToCollectionFromWithinSourceModal}>
              <MarsIcon
                icon="save"
                width="1rem"
                height="1rem"
                className={classnames(`mars-card__save-icon`, {
                  'mars-icon--fill': isInCollections
                })}
              />
              Add to collection
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SnippetCard;

SnippetCard.propTypes = {
  taxonomy: PropTypes.string.isRequired,
  text: PropTypes.string,
  image: PropTypes.string,
  market: PropTypes.string,
  source: PropTypes.shape({
    momentTitle: PropTypes.string,
    docNo: PropTypes.number,
    title: PropTypes.string,
    brand: PropTypes.string
  })
};
