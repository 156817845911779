import React from 'react';
import classnames from 'classnames';

import './Card.scss';

const Card = ({ children, className }) => {
  return <div className={classnames(`mars-card`, className)}>{children}</div>;
};

export default Card;
