import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import classnames from 'classnames';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Card from '@components/atoms/Card/Card';
import './ArticleCard.scss';
import { getDrupalURL } from '@helpers';

const ArticleCard = ({ data, displayFeatured }) => {
  const {
    title,
    url,
    description,
    imageUrl,
    imageAlt,
    created,
    readTime,
    featured
  } = data;

  const monthFeatured = created.toLocaleDateString(`en-GB`, {
    month: `long`,
    year: `numeric`
  });

  const dateCreated = created.toLocaleDateString(`en-GB`, {
    day: `numeric`,
    month: `short`,
    year: `numeric`
  });

  const CardContent = () => (
    <>
      {imageUrl && typeof imageUrl === 'string' ? (
        <div className="mars-article-card__image-wrapper">
          <img src={`${imageUrl}`} alt={imageAlt} />
          {/* <GatsbyImage image={getImage(imageUrl)} alt={imageAlt} /> */}
        </div>
      ) : (
        <>
          {imageUrl && (
            <div className="mars-article-card__image-wrapper">
              {/* <img src={`${imageUrl}`} alt={imageAlt} /> */}
              <GatsbyImage image={getImage(imageUrl)} alt={imageAlt} />
            </div>
          )}
        </>
      )}
      <div className="mars-article-card__content-wrapper">
        {displayFeatured && (
          <div className="mars-article-card__preface mars-zeta">
            {`${monthFeatured}${featured ? ` Featured` : ``}`}
          </div>
        )}
        <h1 className="mars-delta mars-article-card__title">{title}</h1>
        {displayFeatured && description && (
          <p className="mars-article-card__description">{description}</p>
        )}
        <div className="mars-body-large mars-article-card__info mars-body-small">
          <time dateTime={created.toISOString()}>{dateCreated}</time>
          {` `}
          {readTime && (
            <span className="mars-article-card__readtime">
              {`${readTime} min read`}
            </span>
          )}
        </div>
      </div>
    </>
  );

  return (
    <Card
      className={classnames(`mars-article-card`, `mars-margin-bottom--md`, {
        'mars-article-card--featured': displayFeatured && imageUrl
      })}>
      <article>
        {url ? (
          <Link className={`mars-article-card__link`} to={url}>
            <CardContent />
          </Link>
        ) : (
          <CardContent />
        )}
      </article>
    </Card>
  );
};

ArticleCard.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    description: PropTypes.string,
    imageUrl: PropTypes.string,
    imageAlt: PropTypes.string,
    created: PropTypes.instanceOf(Date).isRequired,
    readTime: PropTypes.string,
    featured: PropTypes.bool
  }).isRequired,
  displayFeatured: PropTypes.bool
};

export default ArticleCard;
