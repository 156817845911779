export default (result, type) => {
  switch (type) {
    case `moment`:
      return {
        title: result.title,
        description: result.description,
        url: result.url,
        species: result.name,
        stage: result.stage,
        type: `search`
      };
    case `dataSnippet`:
      return {
        title: result.category.replace(`_`, ``),
        description: result.description,
        url: result.momentUrl,
        species: result.species,
        stage: result.stage,
        moment: result.moment,
        category: result.category
      };
    case `article`:
      return {
        title: result.title,
        url: result.url
      };
  }
};
