import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import Card from '@components/atoms/Card/Card';
import MarsIcon from '@components/atoms/MarsIcon/MarsIcon';
import munger from './searchResult.munger';

import './SearchResultsCard.scss';

const SearchResultsCard = ({ result, type }) => {
  const { title, description, url, species, stage, moment, category } = munger(
    result,
    type
  );

  let icon = `search`;
  if (typeof category !== `undefined`) {
    icon = category.replace(`_`, ``);
    icon = `content${(icon.charAt(0).toUpperCase() + icon.slice(1)).replace(
      /\s/g,
      ``
    )}`;
    console.log(icon);
  }

  return (
    <Card>
      <Link to={`${url}`} className="mars-search-results-card__read-more">
        <div className="mars-search-results-card mars-padding-y--sm mars-padding-x--md">
          <div className="mars-search-results-card__icon-title mars-margin-bottom--xs">
            <MarsIcon icon={icon} width="1rem" height="1rem" />
            <h3 className="mars-search-results-card__title mars-zeta mars-text-colour--text-meta">
              {title}
            </h3>
          </div>
          <div className="mars-search-results-card__excerpt mars-margin-bottom--xs">
            {/* The value is used with dangerouslySetInnerHTML to preserve the HTML markup. */}
            <span
              className="mars-body-large"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
          <div>
            {species && (
              <>
                In <span className="mars-link">{species}</span>
              </>
            )}

            {species && stage && (
              <>
                {` `}in <span className="mars-link">{stage}</span>
              </>
            )}

            {species && moment && (
              <>
                {` `}in <span className="mars-link">{moment}</span>
              </>
            )}
          </div>
        </div>
      </Link>
    </Card>
  );
};

export default SearchResultsCard;

SearchResultsCard.propTypes = {
  result: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
};
