export default (results) => ({
  name: `Lifestage`,
  label: `lifestage`,
  options: [`Puppy`, `Kitten`, `Adult`, `Senior`].map((option) => {
    return {
      label: option,
      name: option,
      count: results.filter((s) => s.lifestage && s.lifestage.includes(option)).length
    };
  })
});
