export default (results) => ({
  name: `Research type`,
  label: `category`,
  options: [
    `key_fact`,
    `key_quote`,
    `pain_point`,
    `gain_point`,
    `thinking`,
    `feeling`,
    `doing`,
    `observation`
  ].map((option) => {
    return {
      label: option,
      name: option,
      count: results.filter((s) => s.category && s.category === option).length
    };
  })
});
