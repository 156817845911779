import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

import MarsIcon from '@components/atoms/MarsIcon/MarsIcon';
import './SearchSnippetCardListFilters.scss';

const SearchSnippetCardListFilter = ({
  filters,
  onFilterChange,
  preFilter = false
}) => {
  const [categoryOpenIndex, setCategoryOpenIndex] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);

  useEffect(() => {
    if (preFilter) {
      const currentFilters = [{ filter: `category`, value: `key_fact` }];
      setSelectedFilters(currentFilters);
    }
  }, []);

  const toggleOptions = (e, index) => {
    e.preventDefault();
    if (index === categoryOpenIndex) {
      setCategoryOpenIndex(-1);
      return;
    }
    setCategoryOpenIndex(index);
  };

  const clearAll = () => {
    setSelectedFilters([]);
    onFilterChange([]);
  };

  const handleFilterCheckbox = (option) => {
    const currentFilters = [...selectedFilters];
    const selectedFilter = {
      filter: filters[categoryOpenIndex - 1].label,
      value: option.name
    };

    const selectedFilterIdx = currentFilters.findIndex(
      (filter) =>
        filter.filter == selectedFilter.filter &&
        filter.value === selectedFilter.value
    );

    if (selectedFilterIdx > -1) {
      currentFilters.splice(selectedFilterIdx, 1);
    } else {
      currentFilters.push(selectedFilter);
    }

    setSelectedFilters(currentFilters);
    onFilterChange(currentFilters);
  };

  return (
    <div className="mars-search-filters mars-margin-bottom--xl">
      <div className="mars-search-filters__level-1">
        <ul className="mars-search-filters__filter-list" role="tablist">
          {filters.map((filter, index) => {
            return (
              <li
                className="mars-search-filters__filter-item"
                key={filter.name + index}
                role="tab"
                aria-selected={index + 1 === categoryOpenIndex}>
                <button
                  className="mars-button mars-button--secondary"
                  onClick={(e) => toggleOptions(e, index + 1)}>
                  <span className="mars-zeta mars-search-filters__filter-name">
                    {filter.name}
                  </span>
                  <MarsIcon
                    icon="down"
                    className={classnames(
                      `mars-search-filters__filter-item-category__icon`,
                      {
                        'mars-search-filters__filter-item-category__icon--open':
                          index + 1 === categoryOpenIndex
                      }
                    )}
                    onClick={(e) => toggleOptions(e, index + 1)}
                  />
                </button>
                <div
                  className={classnames(`mars-search-filters__level-2`, {
                    'mars-search-filters__filter-item--open':
                      index + 1 === categoryOpenIndex
                  })}>
                  <ul
                    className="mars-search-filters__filter-options"
                    role="tabpanel"
                    id={filter.name}>
                    {filter.options.map((option, index) => {
                      return (
                        <li
                          className="mars-search-filters__filter-option"
                          key={option.name + index}>
                          <input
                            onChange={() => handleFilterCheckbox(option)}
                            type="checkbox"
                            checked={
                              !!selectedFilters.find(
                                (filter) => filter.value === option.name
                              )
                            }
                            name={option.name}
                            id={option.name}
                          />
                          <label htmlFor={option.name}>
                            {option.label.replace(`_`, ` `)} (
                            <span>{option.count}</span>)
                          </label>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </li>
            );
          })}
        </ul>
      </div>

      <div className="mars-text--center">
        <span>
          {selectedFilters.map((filter, i) => (
            <span className="mars-search-filters__selected" key={filter.value + i}>
              {filter.value.replace(`_`, ` `)}
            </span>
          ))}
        </span>
        {selectedFilters.length > 0 && (
          <button className="mars-link mars-margin-x--sm" onClick={clearAll}>
            Clear all
          </button>
        )}
      </div>
    </div>
  );
};

export default SearchSnippetCardListFilter;
