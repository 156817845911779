import React from 'react';
import MarsIcon from '@components/atoms/MarsIcon/MarsIcon';

import './NoSavedItems.scss';

const NoSavedItems = ({
  icon = `empty`,
  title = `No items saved yet!`,
  description = `Start saving items to see them here`
}) => {
  return (
    <div className="mars-no-saved-items mars-padding--lg">
      <MarsIcon icon={icon} width="40px" height="40px" />
      <p className="mars-body mars-no-saved-items__text">{title}</p>
      <p className="mars-body-small mars-text-colour--text-meta">
        {description}
      </p>
    </div>
  );
};

export default NoSavedItems;
