export default (results) => ({
  name: `Pet Parent Type`,
  label: `parent`,
  options: [`Millennial`, `Gen Z`].map((option) => {
    return {
      label: option,
      name: option,
      count: results.filter((s) => s.parent && s.parent.includes(option)).length
    };
  })
});
