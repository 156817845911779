export default (results) => ({
  name: `Market`,
  label: `market`,
  options: [
    `Australia`,
    `Brazil`,
    `Canada`,
    `China`,
    `France`,
    `Germany`,
    `Italy`,
    `Japan`,
    `Poland`,
    `Russia`,
    `Sweden`,
    `Thailand`,
    `United Kingdom`,
    `United States`
  ].sort().map((option) => {
    return {
      label: option,
      name: option,
      count: results.filter((s) => s.market && s.market === option).length
    };
  })
});
