import { useState, useEffect } from 'react';
import axios from 'axios';

const useElastic = (index, type, term) => {
  const [searching, setSearching] = useState(false);
  const [results, setResults] = useState({
    moments: [],
    dataSnippets: [],
    contentSnippets: [],
    articles: []
  });
  const [searched, setSearched] = useState(false);

  useEffect(() => {
    if (term && term !== ``) {
      setSearching(true);
      axios.get(`/api/search/${index}/${type}/${term}`).then((r) => {
        setResults({ ...results, ...r.data });
        setSearched(true);
        setSearching(false);
      });
    }
  }, [
    results.moments.length,
    results.dataSnippets.length,
    results.contentSnippets.length,
    results.articles.length,
    searched,
    index,
    type,
    term
  ]);

  return { results, searching };
};

export { useElastic };
